import React from 'react';
import { graphql } from 'gatsby';
import { Container } from 'react-bootstrap';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { Graphql } from '@models/graphql';

import './collage.scss';
import { Image } from '@shared/models/image';

type CollageProps = {
  node: Graphql
}

export default function CollageParagraph({ node }: CollageProps): React.ReactElement {
  return (
    <div className={`overflow-hidden ${node.margin ?? ''} py-5`}>
      {(!node.variant
        || node.variant === 'default') && (
        <Container>
          {node?.title && (
            <h2 className="text-dark-indigo mt-4 text-center">
              {node.title}
            </h2>
          )}
          {node?.subtitle && <p className="text-center">{node.subtitle}</p>}
          {node?.relationships?.images && (
            <div className="image-columns">
              <RowImages images={node.relationships.images} imgClassName="w-100" />
            </div>
          )}
        </Container>
      )}
      {
        node.variant === 'landing' && (
          <>
            {node?.title && (
              <h2 className="text-dark-indigo mt-4 text-center">
                {node.title}
              </h2>
            )}
            {node?.subtitle && <p className="text-center">{node.subtitle}</p>}
            {node?.relationships?.images && (
              <div className="image-slider">
                <RowImages className="primary" images={node.relationships.images} imgWidth="9rem" />
                <RowImages className="secondary" images={node.relationships.images} imgWidth="9rem" />
              </div>
            )}
          </>
        )
      }
    </div>
  );
}

type RowImagesProps = {
  images: Image[],
  className?: string,
  imgClassName?: string,
  imgWidth?: string
}

RowImages.defaultProps = {
  className: '',
  imgClassName: '',
  imgWidth: ''
};

function RowImages({
  images, className, imgClassName, imgWidth
}: RowImagesProps) {
  if (images.length <= 0) {
    return <></>;
  }

  return (
    <div className={`image-rows ${className}`}>
      {images.map((item) => {
        let image;
        let altImage = '';

        if (item.data) {
          image = getImage(item.data);
          altImage = item.attributes?.alt || '';
        }

        return (
          <>
            {image && (
              <GatsbyImage
                key={item.id}
                image={image}
                alt={altImage}
                className={imgClassName}
                objectFit="cover"
                style={imgWidth ? { width: imgWidth } : {}}
              />
            )}
          </>
        );
      })}
    </div>
  );
}

export const fragment = graphql`
  fragment CollageParagraph on paragraph__paragraph_collage {
    id
    title: field_pg_title
    variant: field_variant
    subtitle: field_pg_subtitle
    margin: field_pg_margin
    relationships {
      images: field_pg_images {
        id
        name
        data: gatsbyImageData(layout: FIXED)
        attributes: field_media_image {
          alt
          title
        }
      }
    }
  }
`;
